<template>
  <div class="card mb-3 flag-bg" :style="marketStyle">
    <div class="card-body p-3">
      <div class="d-flex justify-content-between mb-2">
        <div>
          <h3 class="mb-1">{{marketTitle}}</h3>
          <h6 class="mb-1 font-weight-normal">ราคา {{price | amountFormat}} บาท/ชุด <span v-if="rates.length > 1" @click="changePrice" class="change-price text-primary">(เปลี่ยนราคา)</span></h6>
          <!-- <h6 class="mb-1 text-secondary">ส่วนลด {{discount | amountFormat}}%</h6> -->
          <h6 class="mb-0 font-weight-normal">ปิดรับ {{closeTime}} น.</h6>
        </div>
        <div>
          <div>{{roundDate}}</div>
          <div v-if="roundIcon" class="float-right">
            <img :src="roundIcon" style="width: 90px;">
          </div>
        </div>
      </div>
      <PrizeTable :market="market" :rate="rate" />
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import PrizeTable from './PrizeTable'

export default {
  name: 'LottoInfo',
  props: ['round'],
  components: {
    PrizeTable
  },
  computed: {
    market() {
      if(!this.round)
        return ''

      return this.round?.market ?? {}
    },
    rates() {
      if(!this.round)
        return []

      return this.round?.rates ?? []
    },
    rate() {
      if(!this.round)
        return ''

      return this.round?.rate ?? {}
    },
    marketTitle() {
      return this.market?.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    closeTime() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.close).format("DD/MM/YYYY HH:mm")
    },
    price() {
      if(!this.round)
        return 0

      return this.round?.rate?.price
    },
    discount() {
      if(!this.round)
        return 0

      return this.round?.rate?.discount
    },
    marketStyle() {
      const css = []

      if(this.market?.colors?.background) {
        css.push(`background-color: ${this.market.colors.background} !important;`)
      }

      if(this.market?.colors?.text) {
        css.push(`color: ${this.market.colors.text} !important;`)
      }

      if(this.market?.colors?.border) {
        css.push(`border-color: ${this.market.colors.border} !important;`)
      }

      if(this.market?.imageBg) {
        css.push(`--background-url: url(${this.market.imageBg});`)
      }

      return css.join('; ')
    }
  },
  methods: {
    changePrice() {
      this.$emit('changePrice')
    }
  }
}
</script>
<style lang="scss" scoped>
.change-price {
  cursor: pointer;
  font-weight: normal;
}

.change-price:hover {
  text-decoration: underline;
}

.flag-bg::before {
  content: "";
  background-repeat: repeat-y;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  z-index: 0;
  background-image: var(--background-url);
  opacity: 0.1;
}

.flag-bg, .flag-bg div, .flag-bg h5, .flag-bg table {
  position: relative;
}
</style>
